import { Box, Flex } from "@chakra-ui/layout"
import React, { useState } from "react"
import YouTube from "react-youtube"
import { CloseIcon } from "@chakra-ui/icons"

const YoutubePlayerDesktop = props => {
  const [opts] = useState({
    height: "390",
    width: "640",
    playerVars: {
      autoplay: 1,
    },
  })

  return (
    <Box position="fixed" bottom={10} right={10}>
      <Flex justify="flex-end" mb="md">
        <CloseIcon
          w={5}
          h={5}
          color="whiteAlpha.900"
          cursor="pointer"
          onClick={() => props.toggleClip(-1)}
        />
      </Flex>

      {props.projectList.find(c => c.id === props.clipContent).youtubeUrl && (
        <YouTube
          videoId={
            props.projectList.find(c => c.id === props.clipContent).youtubeUrl
          }
          opts={opts}
        />
      )}
    </Box>
  )
}

export default YoutubePlayerDesktop
