import {
  Box,
  Divider,
  Flex,
  Icon,
  Link,
  SimpleGrid,
  Spacer,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react"
import React, { useState } from "react"
import { AiFillLock } from "react-icons/ai"
import Image from "../components/Image/Image"
import Layout from "../components/Layout/Layout"
import StreamingList from "../components/StreamingList/StreamingList"
import YoutubePlayerDesktop from "../components/YoutubePlayerDesktop/YoutubePlayerDesktop"
import YoutubePlayerMobile from "../components/YoutubePlayerMobile/YoutubePlayerMobile"

const IndexPage = () => {
  const [clipContent, setClipContent] = useState(-1)
  const [opacity, setOpacity] = useState(0)
  const [actualSelect, setActualSelect] = useState(0)
  const variant = useBreakpointValue({ base: true, md: false })

  const [projectList] = useState([
    {
      id: 25,
      youtubeUrl: "5ibEEgwapYY",
      image: "houdihoodvol1.jpg",
      title: "HOUDI - HOUD VOL. 1",
      spotifyUrl:
        "https://open.spotify.com/track/6lVWJMQJKfvgpph5Vz1lfA?autoplay=true",
      deezerUrl: "https://www.deezer.com/us/track/2589517102",
      appleMusicUrl: "https://music.apple.com/fr/album/hlm-parasol/1721878632?i=1721878634",
      mixed: "<span>FULL PROJECT MIXED & MASTERED BY PUSHK</span>",
      produce: "<span>Produced by Pushk</span> <br/> HLM PARASOL (w/ <i>GIOVANNI & AERON</i>) <br/> DERAPAGES <i>Ft. LESRAM (W/ GIOVANNI & SOBEK</i>)  <br/> OP <i>(W/ GIOVANNI)</i> <br/> NUAGE (Ft. WINNTERZUKO) <i>(W/ GIOVANNI & SOBEK)</i>  <br/> À PERTE (Ft. DOSSEH) <i>(W/ GIOVANNI & KEN&RYU)</i>",
      label: "© 2024. LA RÊVERIE. DISTRIBUTED By. ADA. WARNER",
      year: "2024",
      comingSoon: false,
    },
    {
      id: 24,
      youtubeUrl: "pNe0a7FI7ZM",
      image: "solalune-pluie.png",
      title: "SO LA LUNE - L'ENFANT DE LA PLUIE",
      spotifyUrl:
        "https://open.spotify.com/album/7ppBSJ1F7MBfqRdHoPUipx?autoplay=true",
      deezerUrl: "https://www.deezer.com/us/album/519746042",
      appleMusicUrl: "https://music.apple.com/fr/album/lenfant-de-la-pluie/1718516257",
      mixed: "",
      produce: "<span>Produced by Pushk</span> <br/> Un jour d'été (w/ <i>GIOVANNI & Yomi</i>) <br/> Toits de la ville (w/ <i>Giovanni & VRSA</i>)",
      label: "© 2023. Low Wood",
      year: "2023",
      comingSoon: false,
    },
    {
      id: 23,
      youtubeUrl: "jXX1yJyFHRk",
      image: "chat-noir.jpg",
      title: "YUNG POOR ALO - Chat noir",
      spotifyUrl:
        "https://open.spotify.com/intl-fr/album/7biY42MHFUyTftW8PiPnPE?autoplay=true",
      deezerUrl: "https://www.deezer.com/fr/album/497593911",
      appleMusicUrl: "https://music.apple.com/fr/album/chat-noir/1710151055",
      mixed: "<span>FULL PROJECT MIXED & MASTERED BY PUSHK</span>",
      produce: "FULL PROJECT PRODUCED BY PUSHK & GIOVANNI",
      label: "© 2023. Distributed By Low Wood ",
      year: "2023",
      comingSoon: false,
    },
    {
      id: 22,
      youtubeUrl: "sv08UBeEGyE",
      image: "wdta.jpg",
      title: "J9ueve - WDTA",
      spotifyUrl:
        "https://open.spotify.com/intl-fr/track/3eHluH7TTjCYboPXZ2ISBx",
      deezerUrl: "https://www.deezer.com/fr/album/463765295",
      appleMusicUrl: "https://music.apple.com/fr/album/wdta-single/1697153643",
      mixed: "<span>Mixed by Pushk :</span>",
      produce: "On se parle plus maintenant",
      label: "© 2023. Distributed by Also. Sony Music",
      year: "2023",
      comingSoon: false,
    },
    {
      id: 21,
      youtubeUrl: "Di58jmx5mR8",
      image: "sun7.png",
      title: "Houdi - SUN7",
      spotifyUrl:
        "https://open.spotify.com/intl-fr/track/3fRGuva8aZ9FvESJVhnKae?autoplay=true",
      deezerUrl: "https://www.deezer.com/fr/track/2336306935",
      appleMusicUrl:
        "https://music.apple.com/fr/album/sun7/1693683722?i=1693684419",
      mixed: "<span>Mixed & Mastered by Pushk</span>",
      produce:
        "<span>Produced by Pushk</span> <br/> Sensation (w/ <i>GIOVANNI</i>) <br/> Avant toi (w/ <i>GIOVANNI</i>) <br/> Scooter (w/ <i>GIOVANNI, Sobek, Franklin</i>) <br/> Sun7 (w/ <i>GIOVANNI</i>)",
      label: "© 2023. La rêverie. Distributed by ADA",
      year: "2023",
      comingSoon: false,
    },
    {
      id: 20,
      youtubeUrl: "CJmSof-zYdM",
      image: "nouja.jpeg",
      title: "8RUKI - Nou Ja Sav Sa",
      spotifyUrl:
        "https://open.spotify.com/intl-fr/track/23TAqT0iIwolt5BlcnY7GP?autoplay=true",
      deezerUrl: "https://www.deezer.com/fr/track/2334362975",
      appleMusicUrl:
        "https://music.apple.com/fr/album/nou-ja-sav-sa/1693349342?i=1693349344",
      mixed: "<span>Mixed & Mastered by Pushk</span>",
      produce: "<span>Produced by Pushk (w/ Giovanni)</span>",
      label: "© 2023 33 Recordz",
      year: "2023",
      comingSoon: false,
    },
    {
      id: 19,
      youtubeUrl: "zfnnghS6c_8",
      image: "rarissime.jpg",
      title: "thaHomey - Rarissime",
      spotifyUrl:
        "https://open.spotify.com/intl-fr/album/6h6nST09aca7F6ihMa0WWU?autoplay=true",
      deezerUrl: "https://www.deezer.com/fr/album/441946377",
      appleMusicUrl: "https://music.apple.com/fr/album/rarissime/1688309851",
      mixed: "<span>Full Project Mixed & Mastered by Pushk</span>",
      produce: "",
      label: "© 2023 Propulsion Music Family",
      year: "2023",
      comingSoon: false,
    },
    {
      id: 18,
      youtubeUrl: "kRWEgM-lGNI",
      image: "mob.jpg",
      title: "Zamdane - Mob",
      spotifyUrl:
        "https://open.spotify.com/intl-fr/album/07JcrUKjzCDu3fkEtiW1XP?si=r-T7RjjITKGP9I96zbCPcA",
      deezerUrl: "https://www.deezer.com/fr/album/439222327",
      appleMusicUrl:
        "https://music.apple.com/fr/album/mob-affam%C3%A9-20/1686359579?i=1686359591",
      mixed: "<span>Mixed & Mastered by Pushk</span>",
      produce: "<span>Produced by Pushk (w/ Giovanni)</span>",
      label: "© 2023 Affamés Records / Bendo Music",
      year: "2023",
      comingSoon: false,
    },
    {
      id: 17,
      youtubeUrl: "bUsWcTCKbz4",
      image: "cover-letsgo.jpg",
      title: "Pushk Giovanni - Let's Go",
      spotifyUrl:
        "https://open.spotify.com/intl-fr/album/07JcrUKjzCDu3fkEtiW1XP?si=r-T7RjjITKGP9I96zbCPcA",
      deezerUrl: "https://www.deezer.com/fr/album/432798857",
      appleMusicUrl: "https://music.apple.com/fr/album/lets-go/1683878496",
      mixed: "<span>Full Project Mixed by Pushk</span>",
      produce: "<span>Full Project Produced by Pushk & Giovanni</span>",
      label: "© 2023 Safe House Music sous licence exclusive Panenka Music",
      year: "2023",
      comingSoon: false,
    },
    {
      id: 16,
      youtubeUrl: "Q5N5kJxQPsc",
      image: "j9ueve-bijou.jpg",
      title: "J9ueve - Le Bijou le plus Brillant",
      spotifyUrl: "https://open.spotify.com/album/2t4DAcRO1ixZ3HUsYWnZJB",
      deezerUrl: "https://www.deezer.com/fr/album/410419197",
      appleMusicUrl:
        "https://music.apple.com/fr/album/le-bijou-le-plus-brillant/1673742727",
      mixed: "",
      produce:
        "<span>Mixed by Pushk</span> <br/> INTRO <br/> DIAMANT <br/> LES SERPENTS (Ft. <i>Rounhaa</i>) <br/> SEX W/ ME <br/> LE MONDE ETAIT À NOUS <br/> SANS FAIRE EXPRES <br/> LE BIJOU LE PLUS BRILLANT <br/> LOUPER LE COCHE <br/> DESIRE (Ft. <i>Ashbeck</i>) <br/> BBY LUV",
      label: "© 2023. Distributed by ALSO. Sony Music",
      year: "2023",
      comingSoon: false,
    },
    {
      id: 15,
      youtubeUrl: "tfwiRTB9ghw",
      image: "folie.jpeg",
      title: "Houdi - La Folie des Grandeurs",
      spotifyUrl:
        "https://open.spotify.com/album/2T9mGRM8c338wAsgQyLmjf?si=VRElSyIuSWClfuB3vjoSbw",
      deezerUrl: "https://www.deezer.com/en/album/408820357",
      appleMusicUrl:
        "https://music.apple.com/fr/album/la-folie-des-grandeurs/1672628011",
      mixed: "<span>All project Mixed & Mastered by Pushk</span>",
      produce:
        "<span>Produced by Pushk</span> <br/> La folie des grandeurs (w/ <i>GIOVANNI</i>) <br/> Musique grosse quantité (w/ <i>GIOVANNI</i>) <br/> 20 ans ft. 34Murphy (w/ <i>GIOVANNI</i>) <br/> Calmos (w/ <i>GIOVANNI</i>) <br/> Ciel (w/ <i>GIOVANNI</i>) <br/> Monaco (w/ <i>GIOVANNI</i>)",
      label: "© 2023. La rêverie",
      year: "2023",
      comingSoon: false,
    },
    {
      id: 14,
      youtubeUrl: "IHDIU4LcPHo",
      image: "confessions.png",
      title: "BabySolo33 - SadBaby Confessions",
      spotifyUrl: "https://open.spotify.com/album/0Hl3YmHFAu17AQdwMgLBth",
      deezerUrl: "https://www.deezer.com/album/368602507",
      appleMusicUrl:
        "https://music.apple.com/fr/album/sadbaby-confessions/1650981515",
      mixed: "<span>Mixed & Mastered by Pushk</span>",
      produce:
        "<span>Produced by Pushk</span> <br/> FuckMoney (w/ <i>GIOVANNI x NODY</i>)",
      label: "© 2022. Jeune à Jamais",
      year: "2022",
      comingSoon: false,
    },
    {
      id: 0,
      youtubeUrl: "sMEnxGhj1NA",
      image: "kenna.jpg",
      title: "So La Lune - KENNA ",
      spotifyUrl: "https://open.spotify.com/album/76pipx8axggIWa1sXWV7K4",
      deezerUrl: "https://www.deezer.com/fr/album/367014877",
      appleMusicUrl: "https://music.apple.com/fr/album/kenna-ep/1650215869",
      mixed: "<span>Mixed & Produced by PUSHK :</span>",
      produce: "<span>Vie d’Avant</span> (w/ <i>GIOVANNI</i>)",
      label: "© 2022. LOW WOOD",
      year: "2022",
      comingSoon: false,
    },
    {
      id: 1,
      youtubeUrl: "OmCgo-TpdK4",
      image: "quantum.jpg",
      title: "MAHDI BA - QUANTUM ",
      spotifyUrl: "https://open.spotify.com/album/4XRIEA9Nohz0KuHsBQRbxI",
      deezerUrl: "https://www.deezer.com/fr/album/360947877",
      appleMusicUrl: "https://music.apple.com/us/album/quantum/1647243897",
      mixed:
        "<span>Mixed & Mastered by Pushk</span> <br/> J9UEVE - Classer mes Idées <br/> So La Lune - Néant  <br/>  Youv Dee - Génie  <br/> Savage Toddy - Memphis  <br/>  Jordan Astra & AOL - Break",
      produce:
        "<span>Produced by Pushk</span> <br/> So La Lune - Neant (w/ <i>GIOVANNI</i>)<br/> Jordan Astra & AOL - Break (w/ <i>GIOVANNI</i>)",
      label: "© 2022. Unknown",
      year: "2022",
      comingSoon: false,
    },
    {
      id: 2,
      youtubeUrl: "hDdKfQHkYYM",
      image: "j9ueve-leplan.jpg",
      title: "J9ueve - Le Plan",
      spotifyUrl: "https://open.spotify.com/album/3pZ3frBpqWRk1EzIvYBIOQ",
      deezerUrl: "https://www.deezer.com/track/1812871717",
      appleMusicUrl: "https://music.apple.com/fr/album/le-plan/1632896309",
      mixed: "<span>ALL PROJECT MIXED & MASTERED BY PUSHK</span>",
      produce:
        "<span>Produced by Pushk</span> <br/> SANS TOI (Ft. <i>ZAMDANE</i>) (W/ <i>GIOVANNI</i>)",
      label: "© 2022. AJ Records distributed by ALSO, Sony Music",
      year: "2022",
      comingSoon: false,
    },
    {
      id: 3,
      youtubeUrl: "9lz-KRkVLQ0",
      image: "zakifinal.jpg",
      title: "Zaky - Sentiments",
      spotifyUrl: "https://open.spotify.com/album/2jVYApVjEq4rk8l4OquNi2",
      deezerUrl: "https://www.deezer.com/fr/album/317078097",
      appleMusicUrl: "https://music.apple.com/fr/album/sentiments/1623142955",
      mixed: "<span>ALL PROJECT MIXED & MASTERED BY PUSHK</span>",
      produce:
        "<span>Produced by Pushk</span> <br/> SENTIMENT, CIEL (Ft. <i>J9UEVE</i>) (W/ <i>GIOVANNI, SO100LEAN</i>), EUX (W/ <i>GIOVANNI</i>), A+ (Ft. <i>BABY SOLO</i>), AMENNE, Z'n'R (Ft. <i>8RUKI</i>) (W/ <i>GIOVANNI</i>), ALONE (W/ <i>NODY</i>), ARGENT SAL (W/ <i>SKUNA</i>), RISK (Ft. <i>DECIMO</i>) (W/ <i>GIOVANNI</i>), PENSÉE (W/ <i>GIOVANNI, YOMI, OCTO</i>), MUDDY (Ft. <i>SLIM C 667</i>) (W/ <i>GIOVANNI</i>), SUCCÈS (W/ <i>NODY</i>) ",
      label: "© 2022. LO' RECORDS",
      year: "2022",
      comingSoon: false,
    },
    {
      id: 4,
      youtubeUrl: "BnaB4c-hMqY",
      image: "ruki-powered.jpg",
      title: "8ruki - PoweredByRuki +",
      spotifyUrl: "https://open.spotify.com/album/32CW9VwLuOrlAuclAEdcsT",
      deezerUrl: "https://www.deezer.com/fr/album/312399607",
      appleMusicUrl:
        "https://music.apple.com/fr/album/poweredbyruki/1620207945",
      mixed: "<span>ALL PROJECT MIXED & MASTERED BY PUSHK</span>",
      produce:
        "<span>Produced by Pushk</span> <br/> N8CK (w/ <i>GIOVANNI</i> ), ALEAS (w/ <i>GIOVANNI & YUNG G</i>) <br/> Magenta (w/ <i>GIOVANNI</i> & <i>C4PA</i>), La Soluce (w/ <i>GIOVANNI</i>)",
      label: "© 2022, 33 Recordz",
      year: "2022",
      comingSoon: false,
    },
    {
      id: 5,
      youtubeUrl: "k2MUF7lWdMM",
      image: "jmks.jpg",
      title: "JMK$ - Bad Boy Romance",
      spotifyUrl:
        "https://open.spotify.com/album/6uVDOLYaKiiIalUQwbWHd6?si=Y6-T4RzqRH6JMk0yRiR_Qg",
      deezerUrl: "https://www.deezer.com/fr/album/306815637",
      appleMusicUrl:
        "https://music.apple.com/fr/album/bad-boy-romance/1616757154",
      mixed:
        "<span>Mixed & Mastered by PushK</span> <br/> Mowgli, 100 Fautes, Non-Stop, Mensonge (Ft. <i>KHALI, J9UEVE</i>), 1 2 3 (PUSSY), A la folie (Ft. <i>LA FÈVE</i>), Palir (Ft. <i>DAFLIKY</i>), Immature, You, Gadji, Sxuthside, Rarri (Ft. <i>AFRO S & BU$HI</i>)",
      produce:
        "<span>Produced by Pushk</span> <br/> Mowgli (W/ <i>GIOVANNI</i>), 100 Fautes, Mensonge (W/ <i>Giovanni & C4PA</i>), 1 2 3 (W/ <i>C4PA, JW8L & MINDTHEGAP</i>",
      label: "© 2022, 8848",
      year: "2022",
      comingSoon: false,
    },
    {
      id: 6,
      youtubeUrl: "Ky-Bu_7MiII",
      image: "rarefiles2.jpg",
      title: "thaHomey - RAREFILES 2",
      spotifyUrl: "https://open.spotify.com/album/0GK3tpLnW7WJEfIzwgWgSg",
      deezerUrl: "https://www.deezer.com/album/296042932",
      appleMusicUrl: "https://music.apple.com/fr/album/1610159352",
      mixed:
        "<span>Mixed & Mastered by PushK</span> <br/> Maintenant, Envie de flex, Full Mob (Ft. <i>ROZZY</i>), X-MEN (Ft. <i>63OG</i>), Late Night, NFT Money, De Toutes Les Facons (Ft. <i>BIG B</i>), OuttaHood, Ma Shit (Ft. <i>SCOTT SOUTH</i>), Clones, Drop Out, Skip To My Lou, Qui Je Suis (Ft. <i>SERANE</i>), Ce Bag",
      produce: "<span>All project Masterised By Pushk</span>",
      label: "© 2022, Propulsion",
      year: "2022",
      comingSoon: false,
    },
    {
      id: 7,
      youtubeUrl: "c359S6Xe2C0",
      image: "illusion.jpg",
      title: "J9ueve - Illusion",
      spotifyUrl: "https://open.spotify.com/album/1FkOTK9JKmbBnyolSZpZuY",
      deezerUrl: "https://www.deezer.com/fr/album/292569242",
      appleMusicUrl: "https://music.apple.com/us/album/1608237397",
      mixed:
        "<span>Mixed & Mastered by PushK</span> <br/> Présidentiel, Melody, Flou, Lemon, 50%, Number One, No More, La belle vie",
      produce: "<span>Produced By Pushk</span> <br/> La belle vie",
      label: "© 2022, AJ Records distributed by ALSO, Sony Music",
      year: "2022",
      comingSoon: false,
    },
    {
      id: 8,
      youtubeUrl: "qivIifY-3I0",
      image: "self-zaky.jpg",
      title: "Zaky - Self",
      spotifyUrl: "https://open.spotify.com/album/2aGZA8zKXcMcV3q0DnEZWT",
      deezerUrl: "https://www.deezer.com/fr/album/265060822",
      appleMusicUrl: "https://music.apple.com/fr/album/self/1589764402?uo=4",
      mixed: "<span>ALL PROJECT MIXED & MASTERED BY PUSHK</span>",
      produce:
        "<span>Produced By Pushk</span> <br/> Stick, Au Delà w/ <i>Skuna</i>, Hobbies w/ <i>Giovanni</i>, Stamina (ft. <i>So La Lune</i>) w/ <i>Giovanni</i>, BTC (ft. <i>8ruki</i>)",
      label: "© 2021, LO' Records",
      year: "2021",
      comingSoon: false,
    },
    {
      id: 9,
      youtubeUrl: "uMLhCOhwE2U",
      image: "dropout.jpg",
      title: "thaHomey - Drop Out",
      spotifyUrl:
        "https://open.spotify.com/track/3825ll39Hp6d0o34mGH8Zt?autoplay=true",
      deezerUrl: "https://www.deezer.com/fr/track/1518435322?autoplay=true",
      appleMusicUrl:
        "https://music.apple.com/fr/album/drop-out/1589809676?i=1589809679",
      mixed: "<span>SINGLE MIXED & MASTERED BY PUSHK</span>",
      produce: "",
      label: "© 2021, Propulsion",
      year: "2021",
      comingSoon: false,
    },
    {
      id: 10,
      youtubeUrl: "FElimTcN-f0",
      image: "8gang.jpg",
      title: "JMK$ & 8ruki - 8GANG",
      spotifyUrl:
        "https://open.spotify.com/album/0Hm12S8xWEBrkzIE18K5XA?si=F2ZBwmfFRRmYdd00Sx-t8Q&nd=1",
      deezerUrl: "https://www.deezer.com/us/album/257854602",
      appleMusicUrl: "https://music.apple.com/fr/album/8gang/1585341218",
      mixed: "<span>ALL PROJECT MIXED & MASTERED BY PUSHK</span>",
      produce:
        "<span>Produced By Pushk</span> <br/> Tree Bags (ft. <i>Zaky</i>)",

      label: "© 2021, 33Recordz & 8848",
      year: "2021",
      comingSoon: false,
    },
    {
      id: 11,
      youtubeUrl: "Fsy8LecQ7Kk",
      image: "j9ueve-arc.jpg",
      title: "J9ueve - Arc-en-ciel",
      spotifyUrl:
        "https://open.spotify.com/album/4bFrl6SlAk4FtZgTnA7RFx?autoplay=true",
      deezerUrl: "https://www.deezer.com/us/album/235964772?autoplay=true",
      appleMusicUrl: "https://music.apple.com/fr/album/arc-en-ciel/1571332689",
      mixed: "<span>ALL PROJECT MIXED & MASTERED BY PUSHK</span>",
      produce: "",
      label: "© 2021, AJ Records",
      year: "2021",
      comingSoon: false,
    },
    {
      id: 12,
      youtubeUrl: "31njCMFu0jw",
      image: "trillistik.jpg",
      title: "thaHomey & Dirtyiceboyz - Trillistik",
      spotifyUrl:
        "https://open.spotify.com/album/2AwVLU547gXbqas4gIfTul?autoplay=true",
      deezerUrl: "https://www.deezer.com/us/album/230920522?autoplay=true",
      appleMusicUrl: "https://music.apple.com/fr/album/trillistik/1568070756",
      mixed: "<span>ALL PROJECT MIXED & MASTERED BY PUSHK</span>",
      produce: "",
      label: "© 2021, Propulsion",
      year: "2021",
      comingSoon: false,
    },
    {
      id: 13,
      youtubeUrl: "y3joO1gG5PI",
      image: "mym.jpg",
      title: "8ruki & Zaky - MYM",
      spotifyUrl:
        "https://open.spotify.com/album/4BIIXtOkCxGQxC918mC1Vh?si=C3nmjatoRjityJCDZbCKhQ&nd=1",
      deezerUrl:
        "https://www.deezer.com/fr/album/232550242?utm_campaign=clipboard-generic&utm_source=user_sharing&utm_medium=desktop&utm_content=album-232550242",
      appleMusicUrl:
        "https://music.apple.com/fr/album/mym-single-feat-zaky-single/1569137963?at=1001lbRT&ct=891114",

      mixed: "<span>SINGLE MIXED & MASTERED BY PUSHK</span>",
      produce: "",
      label: "© 2021, 33Recordz",
      year: "2021",
      comingSoon: false,
    },
  ])

  const toggleClip = id => {
    setClipContent(id)
  }
  return (
    <Layout>
      <SimpleGrid
        align="center"
        justify="center"
        columns={[1, 2, 3, 3]}
        spacing={8}
        mt="xs"
      >
        {projectList.map((e, i) => {
          return (
            <Box key={i}>
              <Box
                objectFit="cover"
                width="100%"
                height="auto"
                position="relative"
                className="overlay-container"
                cursor={e.comingSoon ? "unset" : "pointer"}
                onClick={() => {
                  if (e.comingSoon) return
                  if (opacity && i === actualSelect) {
                    setOpacity(0)
                    setActualSelect(0)
                  } else {
                    setActualSelect(i)
                    setOpacity(1)
                  }
                }}
              >
                <Image
                  filename={e.image}
                  alt={e.image}
                  width="100%"
                  height="100%"
                  objectFit="cover"
                  position={e.comingSoon ? "absolute" : "unset"}
                  top={0}
                />

                {clipContent !== -1 && clipContent === e.id && variant ? (
                  <Box display={["block", "block", "none", "none"]}>
                    <YoutubePlayerMobile
                      projectList={projectList}
                      toggleClip={toggleClip}
                      clipContent={clipContent}
                    />
                  </Box>
                ) : e.comingSoon ? (
                  <>
                    <Flex
                      transition=".5s ease"
                      bgColor="rgba(0, 0, 0, 0.8);"
                      width="100%"
                      textAlign="left"
                      color="white"
                      align="center"
                      justify="center"
                      flexDir="column"
                      flex={1}
                      h="100%"
                      position="absolute"
                      top={0}
                      cursor="pointer"
                      as={Link}
                      href="https://okletsgo.fr"
                      target="_blank"
                      isExternal
                    >
                      <Flex
                        bgColor="black"
                        borderRadius="full"
                        w={75}
                        h={75}
                        flexDir="column"
                        align="center"
                        justify="center"
                      >
                        <Icon as={AiFillLock} w={55} h={55} color="white" />
                      </Flex>
                      <Text
                        textTransform="uppercase"
                        fontSize={35}
                        fontWeight={800}
                        color="white"
                      >
                        Locked
                      </Text>
                    </Flex>
                  </>
                ) : (
                  <Flex
                    left={0}
                    top={0}
                    opacity={i === actualSelect ? opacity : 0}
                    display={i === actualSelect ? "flex" : "none"}
                    className="overlay-content"
                    transition=".5s ease"
                    bgColor="rgba(0, 0, 0, 0.8);"
                    bottom={180}
                    width="100%"
                    height="100%"
                    position="absolute"
                    py={["xs", "xs", "xs", "sm"]}
                    px="xs"
                    textAlign="left"
                    color="white"
                    align="flex-start"
                    justify="center"
                    flexDir="column"
                  >
                    <Text fontSize={15} mb={[0, 0, 2, 2]} fontWeight={800}>
                      CREDITS
                    </Text>
                    <Box flex={1}>
                      <Text
                        fontSize={11}
                        my={2}
                        color="white"
                        className="credit-container"
                        dangerouslySetInnerHTML={{ __html: e.mixed }}
                      />
                      <Text
                        mt="sm"
                        fontSize={11}
                        color="white"
                        className="credit-container"
                        dangerouslySetInnerHTML={{ __html: e.produce }}
                      />
                    </Box>
                    <Divider borderWidth={1} mt="xs" mb={2} />
                    <Text fontSize={15} mt={2} fontWeight={800}>
                      LISTEN
                    </Text>
                    <SimpleGrid
                      align="center"
                      justify="center"
                      w="100%"
                      pb="sm"
                      columns={2}
                    >
                      <StreamingList toggleClip={toggleClip} project={e} />
                    </SimpleGrid>
                    <Text fontSize={13} textAlign="right" fontStyle="italic">
                      {e.label}
                    </Text>
                  </Flex>
                )}
              </Box>
              {!e.comingSoon && (
                <Flex mt="xs">
                  <Text fontSize={[17, 17, 15, 15]} textAlign="left">
                    {e.title}
                  </Text>
                  <Spacer />
                  <Text fontSize={13}>{e.year}</Text>
                </Flex>
              )}
            </Box>
          )
        })}
      </SimpleGrid>
      {clipContent !== -1 && (
        <Box display={["none", "none", "block", "block"]}>
          <YoutubePlayerDesktop
            projectList={projectList}
            toggleClip={toggleClip}
            clipContent={clipContent}
          />
        </Box>
      )}
    </Layout>
  )
}

export default IndexPage
