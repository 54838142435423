import Icon from "@chakra-ui/icon"
import {  Flex, Link, Text } from "@chakra-ui/layout"
import React from "react"
import { BsSpotify, BsYoutube } from "react-icons/bs"
import { FaDeezer } from "react-icons/fa"
import { SiApplemusic } from "react-icons/si"

const StreamingList = props => {
  return (
    <>
      <Flex
        as={Link}
        href={props.project.spotifyUrl}
        isExternal
        align="center"
        mt={3}
        _hover={{
          color: "rgb(200,200,200)",
          textDecoration: "none",
        }}
        _focus={{
          boxShadow: "none",
        }}
      >
        <Icon w={3} h={3} as={BsSpotify} />
        <Text ml={3} fontSize={12} fontWeight={600}>
          Spotify
        </Text>
      </Flex>
      <Flex
        as={Link}
        href={props.project.deezerUrl}
        isExternal
        align="center"
        mt={3}
        _hover={{
          color: "rgb(200,200,200)",
          textDecoration: "none",
        }}
        _focus={{
          boxShadow: "none",
        }}
      >
        <Icon w={3} h={3} as={FaDeezer} />
        <Text ml={3} fontSize={12} fontWeight={600}>
          Deezer
        </Text>
      </Flex>
      <Flex
        as={Link}
        href={props.project.appleMusicUrl}
        isExternal
        align="center"
        mt={3}
        _hover={{
          color: "rgb(200,200,200)",
          textDecoration: "none",
        }}
        _focus={{
          boxShadow: "none",
        }}
      >
        <Icon w={3} h={3} as={SiApplemusic} />
        <Text ml={3} fontSize={12} fontWeight={600}>
          Apple Music
        </Text>
      </Flex>
      <Flex
        as={Link}
        onClick={() => props.toggleClip(props.project.id)}
        isExternal
        align="center"
        mt={3}
        _hover={{
          color: "rgb(200,200,200)",
          textDecoration: "none",
        }}
        _focus={{
          boxShadow: "none",
        }}
      >
        <Icon w={3} h={3} as={BsYoutube} />
        <Text ml={3} fontSize={12} fontWeight={600}>
          Youtube
        </Text>
      </Flex>
    </>
  )
}

export default StreamingList
