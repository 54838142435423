import { Flex } from "@chakra-ui/layout"
import React, { useState } from "react"
import YouTube from "react-youtube"
import { CloseIcon } from "@chakra-ui/icons"

const YoutubePlayerMobile = props => {
  const [opts] = useState({
    width: "100%",
    height: "100%",
    playerVars: {
      autoplay: 1,
    },
  })

  return (
    <Flex
      position="absolute"
      zIndex={2}
      top={0}
      bgColor="black"
      width="100%"
      height="100%"
      align="center"
      justify="center"
      sx={{
        iframe: {
          width: "100%",
          height: "100%",
        },
        div: {
          width: "100%",
        },
      }}
      flexDir="column"
    >
      <Flex justify="center" mb="md">
        <CloseIcon
          w={5}
          h={5}
          color="whiteAlpha.900"
          cursor="pointer"
          onClick={() => props.toggleClip(-1)}
        />
      </Flex>

      {props.projectList.length > 0 && (
        <YouTube
          videoId={
            props.projectList.find(c => c.id === props.clipContent).youtubeUrl
          }
          opts={opts}
        />
      )}
    </Flex>
  )
}

export default YoutubePlayerMobile
